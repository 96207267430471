
.formHead {
  margin-left: 3rem;
}

.selectKlient {
  width: 69%;
  font-size: 1.5rem;
}

.btnNacist {
  margin-left: 1rem;   
  background-color: #747264;
  color: #ffffff;
  border-radius: 5px; 
  font-size: 1.5rem;
}

.btnNacist:hover {
  background-color: #a7a596;
}

#formInfo {
  width: 30%;
  float: left;
}

.formAddress {
  margin-top: 3.6rem;
}

.selectFirma {
  width: 26.5rem;
  font-size: 1.5rem;  
  margin-top: 1.2rem;
  margin-left: 3rem;  
}

.lblKlienti {
  margin-left: 3rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column-reverse;
  width: 80%;
  margin-top: -0.5rem;
}
.lblKlienti>span {
  transition: all .2s;
  transform-origin: top left;
}
.lblKlienti>input[placeholder=" "]:not(:focus):placeholder-shown + span {
  transform: translateY(1em) scale(1.50);
  pointer-events: none;
  opacity: .5
}





.formButtons {
  
  font-size: 1.5rem;
  margin-left: 3rem;
  margin-top: 2rem;
  padding-bottom: 2rem;

}

.btnEdit {
background-color: #385985;
  color: #ffffff;
  border-radius: 5px;
}

.btnUzivSubmit {
margin-left: 1rem; 
  background-color: #5a8d3c;
  color: #ffffff;
  border-radius: 5px;
}

.btnClr {
margin-left: 1rem; 
  background-color: #979694;
  color: #ffffff;
  border-radius: 5px; 
}

.btnClr:hover {
  background-color: #a7a596;
}

.btnDelete {
  margin-left: 1rem; 
  background-color: #a76c62;
    color: #ffffff;
    border-radius: 5px; 
}

.btnEdit:hover {
  background-color: #90a3bb;   
}

.btnUzivSubmit:hover {
  background-color: #91a884;   
}



.btnDelete:hover {
  background-color: #b39994;
}

.containerUziv {
  max-width: 50%;
  max-height: 50%;
  overflow-y: scroll;
  
}

.containerInputsUziv {
  
  max-width: 95%;
  display: flex;
  font-size: 1.5rem;
  flex-direction: column;
  
}

.inputHledejUziv {
  
  margin-top: 1%;
  width: 70%;
  padding: 5px;
  text-transform: uppercase;
  letter-spacing: 2%;
}

#tablespinner {
  color: #3C3633;
  height: 3rem;
  width: 3rem;
  margin-top: 14%;
  margin-left: 48%;
}

table {
  margin-left: 0;
  margin-top: 1%;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #e7d7cb;
}
th,
td {
  height: 3vh;
  border: 1px solid rgb(0, 0, 0);
  color: #080808;
}
th {
  text-align: left;
  color: #ffffff;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 2%;
}
thead th {
  background-color: #3C3633;
}
tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
  position: relative;
}





