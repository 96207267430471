header, footer {
    font-size: 2.2rem;
    background: #747264; /*718057*/
    height: 9vh;
    color: #ffffff;
  }

  .faCar {
    margin-bottom: 0.4rem;
  }
  
  .lblTitle {
    margin-left: 3rem;
    margin-top: -1rem;
  }

  .uziv {
    font-size: 1.2rem;
    float: right;
    
    margin-right: 2rem;
    color: #ffffff;
  }

  .selectFiNav {
    width: 9rem;
  }

  select,
  select option {
    color: #000000;
  }

  select:invalid,
  select option[value=""] {
    color: #999999;
  }

  label {
    display: block;
    margin: 16px 0;
  }

  /*Added for browser compatibility*/
  [hidden] {
    display: none;
  }

  .logout {
    font-size: 1.2rem;
    margin-top: -1rem;
    margin-right: -1rem;
    float: right;
    height: 9vh;
    background: #3C3633;
    color: #ffffff;
    border-left: 2px solid #ffffff;
    border-top: 0;
    border-right: 0;
    padding: 1rem;
    padding-right: 2rem;
  }

  .logout:hover {
    background-color: #635955;
    
  }
