.modalEd {
    max-height: 85vh;
    overflow-y: auto;
    max-width: 90vw;
    overflow-x: auto;
}

#btnHelp {
    float: right;
    background-color: #3C3633;
    font-size: 1.2rem;
    color: white;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid #F6F4EB;
    border-bottom-left-radius: 15px;
}

#btnHelp:hover {
    background-color: #635955;
}

.helpOdstavec {
    margin-top: 2rem;
}

.btnCloseMod {
    float: right;
    font-size: 2rem;
}

.btnCloseMod:hover {
    color: #bbbbbb;
}