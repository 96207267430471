.flex-container {
    display: flex;
    justify-content: flex-start;
  }
  
  .flex-container > div {
    
                      
    background-color: #3C3633; /*353f31*/
    border: 1px solid #F6F4EB;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-size: 1.2rem;
    color: white;
    
    
    
  }

  .mnu-uziv {
    border-bottom-right-radius: 20px;
  }
  

  .flex-container > div:hover {
    background-color: #635955;
    
  }