.mainNav {
    width: 7.5%;
    min-height: 10vh;
    background: #3C3633; /*353f31*/
}

    .mnavList {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

      .mnavItem {
        width: 100%;
        border-bottom: 2px solid #ffffff;
        list-style: none;
        font-size: 2.4vmin;
        text-align: center;
        transition: all 200ms ease-in;
      }

        a {
          color: #ffffff;
          text-decoration: none;
        }

          .react-icons {
            font-size: 1.8rem;
            margin-bottom: -0.8rem;
          }

        .nav_itemlabel {
          visibility: visible;
        }

        a:hover {
          color:#96837c; 
          text-decoration:none; 
          cursor:pointer;  
        }

@media screen and (max-width: 850px) {
          
    .mainNav {
        height: 15%;
        width: 100%;
        background: #3C3633;
    }
          
        .mnavList {
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding: 0 1rem;
        }
          
            .nav_itemlabel {
                visibility: hidden;
            }            
}
          
@media screen and (max-width: 1150px) {
    .nav_itemlabel {
        visibility: hidden;
    }  
}
