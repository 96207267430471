* {
    box-sizing: border-box;
    font-family:"Calibri", sans-serif;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }
  
    main {
      display: flex;
      height: 100%;
      width: 100%;
    }
  
      article {
        width: 92.5%;
        background: #EEEDEB;
      }
  
    header,
    footer,
    .mainNav {
        padding: 1rem;
      }
   
  @media screen and (max-width: 850px) {
    main {
      flex-direction: column;
    }
      article {
        height: 85%;
        width: 100%;
        background: #EEEDEB;
      }
  }
  
  