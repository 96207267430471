#formInfo {
    width: 100%;
    float: left;
}

.lblKlic {
    margin-left: 3rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
  }
  .lblKlic>span {
    transition: all .2s;
    transform-origin: top left;
  }
  .lblKlic>input[placeholder=" "]:not(:focus):placeholder-shown + span {
    transform: translateY(1em) scale(1.55);
    pointer-events: none;
    opacity: .5
  }

.formDates {
    margin-left: 3rem;
    margin-top: 2rem;
}  

.datumDOX {
  margin-top: 2rem; 
}



.btnKlicSubmit {
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 1.5rem;
    background-color: #747264;
    color: #ffffff;
    border-radius: 5px;
}

.btnKlicSubmit:hover {
    background-color: #a7a596;   
}





