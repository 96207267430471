

#card-header {
    background: #3C3633;
    color: #ffffff;
}

#btn-login {
    background: #747264;
    color: #ffffff;
}

#btn-login:hover {
    background-color: #a7a596;
    
}