.container {
    max-width: 95%;
    max-height: 58%;
    overflow-y: scroll;
    margin: auto;
  }

  .containerInputs {
    
    max-width: 95%;
    display: flex;
    font-size: 1.5rem;
    flex-direction: column;
    margin: auto;
  }
  
  .lblPrehled {
    margin-top: 2rem;
  }
  
  .datumDo {
    margin-left: 50px;
  }

  .u_id {
    font-size: 1.2rem;
    padding: 0.85rem;
    margin-right: 1%;
    letter-spacing: 2%;
    width: 18%;
  }

  .btnInputSubKlient {
    font-size: 1.2rem;
    padding: 0.85rem;
    
    letter-spacing: 2%;
    background-color: #747264;
    color: #ffffff;
    border-radius: 5px;
  }

  .btnInputSub:hover {
    background-color: #a7a596;
    
  }





  .inputHledej {
    
    margin-top: 1%;
    width: 100%;
    padding: 5px;
    text-transform: uppercase;
    letter-spacing: 2%;
  }


  #tablespinner {
    color: #3C3633;
    height: 3rem;
    width: 3rem;
    margin-top: 14%;
    margin-left: 48%;
  }
  

  table {
    margin-left: -0.9%;
    margin-top: 1%;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #e7d7cb;
  }
  th,
  td {
    height: 3vh;
    border: 1px solid rgb(0, 0, 0);
    color: #080808;
  }
  th {
    text-align: left;
    color: #ffffff;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 2%;
  }
  thead th {
    background-color: #3C3633;
  }
  tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  tbody td {
    position: relative;
  }

  