.selectFirmaZar {
    width: 26.5rem;
    font-size: 1.5rem;  
    margin-top: 1.2rem;
      
}

.selectZarizeni {
    width: 63%;
    font-size: 1.4rem;
    margin-left: 3rem;
    margin-top: 1.2rem;
}

.btnNacist {
    margin-left: 1rem;   
    background-color: #747264;
    color: #ffffff;
    border-radius: 5px; 
    font-size: 1.5rem;
}

.btnNacist:hover {
    background-color: #a7a596;
}

#formInfo {
    width: 30%;
    float: left;
}

.formAddress {
    margin-top: 3.6rem;
}

.lblKlienti {
    margin-left: 3rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
  }
  .lblKlienti>span {
    transition: all .2s;
    transform-origin: top left;
  }
  .lblKlienti>input[placeholder=" "]:not(:focus):placeholder-shown + span {
    transform: translateY(1em) scale(1.50);
    pointer-events: none;
    opacity: .5
  }

.formButtons {
    
    font-size: 1.5rem;
    margin-left: 3rem;
    margin-top: 2rem;
    padding-bottom: 2rem;

}

.btnKlientiSubmit {
    background-color: #747264;
    color: #ffffff;
    border-radius: 5px;
}

.btnClear {
  margin-left: 1rem; 
  background-color: #747264;
    color: #ffffff;
    border-radius: 5px; 
}

.btnDelete {
    margin-left: 1rem; 
    background-color: #a76c62;
      color: #ffffff;
      border-radius: 5px; 
  }


.btnKlientiSubmit:hover {
    background-color: #a7a596;   
}

.btnClear:hover {
    background-color: #a7a596;
}

.btnDelete:hover {
    background-color: #b99c97;
}

#tablespinner {
    color: #3C3633;
    height: 3rem;
    width: 3rem;
    margin-top: 14%;
    margin-left: 48%;
  }
  
  #tableZar {
    margin-left: 0;
    margin-top: 1%;
    width: 100%;
    height: 60%;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #e7d7cb;
  }
  th,
  td {
    height: 3vh;
    border: 1px solid rgb(0, 0, 0);
    color: #080808;
  }
  th {
    text-align: left;
    color: #ffffff;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 2%;
  }
  thead th {
    background-color: #3C3633;
  }
  tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  tbody td {
    position: relative;
  }




