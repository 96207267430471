
.formHead {
    margin-left: 3rem;
}

.selectKlient {
    width: 69%;
    font-size: 1.4rem;
    margin-top: 1.2rem;
}

.btnNacist {
    margin-left: 1rem;   
    background-color: #747264;
    color: #ffffff;
    border-radius: 5px; 
    font-size: 1.5rem;
}

.btnNacist:hover {
    background-color: #a7a596;
}

#formInfo {
    width: 30%;
    float: left;
}

.formAddressKl {
    margin-top: 4.6rem;
}

.lblKlienti {
    margin-left: 3rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column-reverse;
    width: 80%;
  }
  .lblKlienti>span {
    transition: all .2s;
    transform-origin: top left;
  }
  .lblKlienti>input[placeholder=" "]:not(:focus):placeholder-shown + span {
    transform: translateY(1em) scale(1.50);
    pointer-events: none;
    opacity: .5
  }





.formButtonsKl {
    
    font-size: 1.5rem;
    margin-left: 3rem;
    margin-top: 2rem;
    padding-bottom: 2rem;

}

.btnKlientiSubmit {
    background-color: #5a8d3c;
    color: #ffffff;
    border-radius: 5px;
}

.btnClr {
    margin-left: 1rem; 
      background-color: #979694;
      color: #ffffff;
      border-radius: 5px;
      font-size: 1.5rem; 
    }
    
    .btnClr:hover {
      background-color: #a7a596;
    }

.btnDelete {
    margin-left: 1rem; 
    background-color: #a76c62;
      color: #ffffff;
      border-radius: 5px; 
  }


.btnKlientiSubmit:hover {
    background-color: #a7a596;   
}

.btnDelete:hover {
    background-color: #b99c97;
}




