#formInfoRz {
    width: 100%;
    float: left;
}

.formDatesRz {
    margin-left: 3rem;
    margin-top: 1.4rem;
}  

.datumDORz {
    padding-left: 1rem;
    
}

.dtmLoad {
  font-size: 2rem;
  color: #747264;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.dtmLoad:hover {
  color: #a7a596;
}

.selectSloupekRz {
  width: 25rem;
  font-size: 1.5rem;  
  margin-top: 1.5rem;
  margin-left: 3rem;  
}

.inputLimitRz {
    font-size: 1.3rem;
    margin-left: 1rem;
    width: 7rem;
}

.selectKlientRz {
  width: 25rem;
  font-size: 1.5rem;  
  margin-top: 1.5rem;
  margin-left: 3rem;  
}

.btnNacist {
  margin-left: 1rem;   
  background-color: #747264;
  color: #ffffff;
  border-radius: 5px; 
  font-size: 1.5rem;
}

.btnNacist:hover {
  background-color: #a7a596;
}

.lblRezervace {
    margin-left: 3rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column-reverse;
    width: 33rem;
  }
  .lblRezervace>span {
    transition: all .2s;
    transform-origin: top left;
  }
  .lblRezervace>input[placeholder=" "]:not(:focus):placeholder-shown + span {
    transform: translateY(1em) scale(1.55);
    pointer-events: none;
    opacity: .5
  }

.btnRzSubmit {
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 1.5rem;
    background-color: #747264;
    color: #ffffff;
    border-radius: 5px;
}

.btnRzSubmit:hover {
    background-color: #a7a596;   
}

.btnClr {
  margin-left: 1rem; 
    background-color: #979694;
    color: #ffffff;
    border-radius: 5px;
    font-size: 1.5rem; 
  }
  
  .btnClr:hover {
    background-color: #a7a596;
  }

.btnHrezervace {
  margin-top: 2rem;
  margin-left: 11.5rem;
  font-size: 1.5rem;
  background-color: #b6a191;
  color: #ffffff;
  border-radius: 5px;
}

.btnHrezervace:hover {
  background-color: #ddccbf;   
}





